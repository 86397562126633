import geoMapper from '@/common/store/mapper/_geoMapper.js';
import format from '@/common/utilities/format';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash/debounce';

// needed for when we run on node versions lower than 15 in some CI environments
import { AbortController } from 'node-abort-controller';
import { getPathByFilter, getFilterByPath } from '../../../../widget/spec/filterhelper';
import { getGeoByPath } from '../../../../widget/spec/geohelper';
import { getStorage } from '../../../common/utilities/nojq/_localstorage.js';
import { redirect } from '../../../common/utilities/nojq/_redirect.js';
let fetchRequest;
let fetchAbort = new AbortController();
const abortFetch = () => {
    fetchAbort.abort();
    fetchAbort = new AbortController();
};
let searchTimer;
const SEARCH_BY = {
    SEARCH_GPS: 'search_gps',
    SEARCH_GEO: 'search_geo',
    SEARCH_RECENT: 'search_recent',
    SEARCH_SAVED: 'search_saved',
    SEARCH_DEFAULT: 'search_default',
    SEARCH_GAUTOCOMPLETE: 'search_gautocomplete',
    SEARCH_AGENT: 'search_agent',
    SEARCH_VETERAN: 'search_veteran',
    SEARCH_LENDER: 'search_lender',
    SEARCH_MARKET_TREND: 'search_market_trend',
    SEARCH_FAVORITE: 'search_favorite',
};

let timer;
export default {
    name: 'mvtSearchbox',
    inject: ['$eventBus'],
    emits: ['update:modelValue'],
    data() {
        return {
            isTyping: false,
            minReqChars: 2,
            index: -1,
            categoryIndex: -1,
            searchText: '',
            suggestLinkList: [],
            isVisible: false,
            searchFilter: null,
            timer: null,
            defaultText: '',
            initialed: false,
            hasInput: false,
        };
    },
    props: {
        type: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
        },
        modelValue: {
            type: Object,
            default: () => {
                return {};
            },
        },
        heroMode: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: String,
            required: false,
            default: 'default',
            validator: (val) => ['default', 'small', 'large'].includes(val),
        },
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getLastSearch', 'getLastSearch', 'getPrevSearch', 'getSplit', 'getRecentSearchList', 'getSavedHomesCount', 'getSavedSearches']),
        _value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        searchSuggestions() {
            if (this.searchText.length > this.minReqChars && this.suggestLinkList.length > 0 && this.hasInput) {
                return this.suggestLinkList;
            }
            return this.defaultSuggestLinkList;
        },
        _getRecentlySearchedSuggestions() {
            const recentlySearch = [];
            const stored = this.getRecentSearchList?.length ? this.getRecentSearchList : $.getStorage('recentSearchList');

            if (Array.isArray(stored) && stored.length > 0) {
                stored.forEach((item) => {
                    if (item.displayText) {
                        recentlySearch.push({
                            text: item.displayText,
                            subText: item.subText,
                            icon: 'icon-search',
                            action: 'redirect',
                            url: item.url,
                            type: 'recentSearch',
                            searchBy: SEARCH_BY.SEARCH_RECENT,
                        });
                    } else {
                        recentlySearch.push({
                            text: item.text,
                            data: item,
                            icon: 'icon-search',
                            action: 'searchByInputText',
                            type: 'recentSearch',
                            searchBy: SEARCH_BY.SEARCH_RECENT,
                        });
                    }
                });
            } else {
                const lastSearch = this.getPrevSearch || this.getLastSearch;
                if (lastSearch?.displayText) {
                    const geo = getGeoByPath(lastSearch.url);
                    let url = '/' + geo.path;
                    if (geo.geoType === 'CITY' && geo.lat && geo.lng) {
                        url += `@${geo.lat},${geo.lng}/`;
                    }
                    recentlySearch.push({
                        text: lastSearch.displayText,
                        icon: 'icon-search',
                        action: 'redirectGeo',
                        url,
                        type: 'recentSearch',
                        searchBy: SEARCH_BY.SEARCH_RECENT,
                    });
                }
            }

            const subscriptions = this.recordTolinks();
            if (subscriptions?.length) {
                recentlySearch.push(...subscriptions.slice(0, 4));
            }

            return recentlySearch;
        },
        _getSavedSearchSuggestions() {
            if (this.getSavedSearches && this.getSavedSearches.length > 0) {
                return this.getSavedSearches.slice(0, 3).map((item) => ({
                    text: item.input || item.searchName,
                    subText: this.createSubtext(item),
                    icon: 'icon-bookmark-o',
                    action: 'redirect',
                    url: '/' + item.url,
                    type: 'savedSearch',
                    searchBy: SEARCH_BY.SEARCH_SAVED,
                }));
            }
            return [];
        },
        _getFavoriteHomeSuggestion() {
            if (this.getSavedHomesCount > 0) {
                return {
                    text: `My Favorite Homes (${this.getSavedHomesCount})`,
                    href: `${this.glb.appUrl}my-movoto/favorites/`,
                    icon: 'icon-heart',
                    type: 'savedHomes',
                    title: 'SAVED Homes',
                    searchBy: SEARCH_BY.SEARCH_FAVORITE,
                };
            }
            return null;
        },
        getFooterSuggestionItems() {
            const geo = this.glb.geo;
            const appUrl = this.glb.appUrl;

            const footerSuggest = [
                {
                    key: 'AGENTS',
                    basePath: 'agents/',
                    icon: 'icon-user',
                    defaultText: 'top local Real Estate agents',
                    searchBy: SEARCH_BY.SEARCH_AGENT,
                },
                {
                    key: 'VETERANS',
                    basePath: 'veterans/',
                    icon: 'icon-shield-star',
                    defaultText: 'Veterans Agents & VA Loans',
                    searchBy: SEARCH_BY.SEARCH_VETERAN,
                    target: '_blank',
                },
                {
                    key: 'LENDERS',
                    basePath: 'mortgages/',
                    icon: 'icon-hand-rate',
                    defaultText: 'mortgage lenders',
                    searchBy: SEARCH_BY.SEARCH_LENDER,
                },
                {
                    key: 'MARKET TRENDS',
                    basePath: 'market-trends/',
                    icon: 'icon-graph-increasing',
                    defaultText: 'market trends',
                    searchBy: SEARCH_BY.SEARCH_MARKET_TREND,
                },
            ];

            return footerSuggest.map((item) => {
                const result = {
                    title: item.key,
                    text: item.defaultText,
                    href: `${appUrl}${item.basePath}`,
                    icon: item.icon,
                    searchBy: item.searchBy,
                };

                if (item.target) {
                    result.target = item.target;
                }

                if (geo && geo.geoType) {
                    const { geoType, city, cityPath, zipcode, zipcodePath } = geo;

                    if (item.searchBy === SEARCH_BY.SEARCH_AGENT) {
                        if (geoType === 'CITY') {
                            result.href = `${appUrl}agents/${cityPath}`;
                            result.text += ` in ${city}`;
                        } else if (geoType === 'ZIPCODE') {
                            result.href = `${appUrl}agents/${zipcodePath}`;
                            result.text += ` in ${zipcode}`;
                        }
                    }

                    if (item.searchBy === SEARCH_BY.SEARCH_LENDER && geoType === 'ZIPCODE') {
                        result.href = `${appUrl}mortgages/?zipcode=${zipcode}`;
                        result.text += ` in ${zipcode}`;
                    }

                    if (item.searchBy === SEARCH_BY.SEARCH_MARKET_TREND) {
                        if (geoType === 'CITY') {
                            result.href = `${appUrl}${cityPath}market-trends/`;
                            result.text += ` in ${city}`;
                        } else if (geoType === 'ZIPCODE') {
                            result.href = `${appUrl}${zipcodePath}market-trends/`;
                            result.text += ` in ${zipcode}`;
                        }
                    }
                }

                return result;
            });
        },
        defaultSuggestLinkList() {
            if (!this.initialed) {
                return [];
            }

            const autoSuggest = [
                {
                    icon: 'icon-location-arrow',
                    action: 'geoSearch',
                    text: 'Current Location',
                    url: '/for-sale/',
                    type: 'current',
                    searchBy: SEARCH_BY.SEARCH_GPS,
                },
            ];

            const recentlySearches = this._getRecentlySearchedSuggestions;
            const savedSearches = this._getSavedSearchSuggestions;
            const favorite = this._getFavoriteHomeSuggestion;

            if (recentlySearches.length) {
                recentlySearches[0].title = 'RECENT SEARCHES';
                autoSuggest.push(...recentlySearches);
            }

            if (savedSearches.length) {
                savedSearches[0].title = 'SAVED SEARCH';
                autoSuggest.push(...savedSearches);
            }

            if (favorite) {
                autoSuggest.push(favorite);
            }

            const footer = this.getFooterSuggestionItems;
            autoSuggest.push(...footer);

            return autoSuggest;
        },
    },
    watch: {
        modelValue(newVal) {
            this.searchText = newVal?.text || '';
            this.defaultText = newVal?.text || '';
        },
    },
    beforeMount() {
        this.$eventBus.$on('eventbus-geosearch-apply', (params) => {
            this.geoSearch(params);
        });
    },
    mounted() {
        this.searchText = this._value?.text || '';
        this.defaultText = this._value?.text || '';
        document.addEventListener('click', this.onClickOutside);
        this.updateSubscriptions(this.recordTolinks());

        // Prebuild default suggestions without blocking the UI
        requestIdleCallback(() => {
            if (!this.initialed) {
                this.initialed = true;
            }
        });
    },
    methods: {
        ...mapMutations('glb', ['updateSubscriptions']),
        /* Suggestion Generation */
        getCompleteSuggestionItems: debounce(function () {
            const displayText = this.searchText?.trim();
            if (!displayText) {
                this.isTyping = false;
                return;
            }
            // Cancel ongoing fetch
            if (fetchRequest?.state === 'pending') abortFetch();

            // Fast early match for address
            if (/(\d+\s+[A-Za-z]+\s+)|(\d+\s+[A-Za-z]{3,})/.test(displayText)) {
                this._getGeoSuggestionData().then((dict) => {
                    this.deferGeoSuggestionBuild(dict || []);
                    this.isTyping = false;
                });
                return;
            }

            const urlParams = new URLSearchParams({
                key: displayText,
                state: (this.glb.geo && this.glb.geo.state) || $.getCookie('lastSearchState'),
            });

            fetchRequest = fetch(`${this.glb.appUrl}api/geo/autosearch_v2/?${urlParams}`, {
                signal: fetchAbort.signal,
            });

            fetchRequest.state = 'pending';

            fetchRequest
                .then((res) => res.json())
                .then((data) => {
                    fetchRequest.state = 'complete';
                    let parsed = data;
                    if (typeof data === 'string') {
                        parsed = JSON.parse(data);
                    }
                    this.deferGeoSuggestionBuild(parsed || []);
                })
                .catch((err) => {
                    if (err.name !== 'AbortError') {
                        console.error('Suggestion fetch error:', err);
                    }
                    this._getGeoSuggestionData().then((dict) => {
                        this.deferGeoSuggestionBuild(dict || []);
                    });
                })
                .finally(() => {
                    this.isTyping = false;
                });
        }, 250),
        deferGeoSuggestionBuild(data) {
            requestIdleCallback(() => {
                const geoItems = this.getGeoSuggestionItems(data);
                const footerItems = this.getFooterSuggestionItems;
                this.suggestLinkList = geoItems.concat(footerItems);
                this.index = -1;
            });
        },
        getGeoSuggestionItems(data) {
            if (!Array.isArray(data) || !data.length) return [];

            const group = {};
            const limits = {
                city: 3,
                county: 1,
                zipcode: 3,
                neighborhood: 1,
                school: 1,
                address: 5,
            };

            const displayOrder = [
                { type: 'address', title: 'ADDRESSES' },
                { type: 'city', title: 'CITIES' },
                { type: 'zipcode', title: 'ZIPCODE' },
                { type: 'neighborhood', title: 'NEIGHBORHOOD' },
                { type: 'county', title: 'COUNTY' },
                { type: 'school', title: 'SCHOOL' },
            ];

            const getGeoInfo = (item, type) => {
                const geo = {
                    lat: item.location?.lat,
                    lng: item.location?.lon,
                    type,
                    state: item.state,
                    city: item.city?.toLowerCase(),
                };

                switch (type) {
                    case 'city':
                        return { geo, text: geo.city, subText: geo.state, icon: 'icon-pin' };
                    case 'county':
                        geo.city = item.county?.toLowerCase();
                        return { geo, text: geo.city, subText: geo.state, icon: 'icon-map' };
                    case 'zipcode':
                        geo.zipcode = item.zipcode;
                        return { geo, text: geo.zipcode, subText: geo.state, icon: 'icon-pin' };
                    case 'neighborhood':
                        geo.neighborhood = item.neighborhood?.toLowerCase();
                        geo.generatePath = true;
                        return {
                            geo,
                            text: geo.neighborhood,
                            subText: `${geo.city}, ${geo.state}`,
                            icon: 'icon-property-lot',
                        };
                    case 'school':
                        geo.schoolName = (item.schoolname || item.name?.input || item.full_name || '').toLowerCase();
                        geo.schoolBoundaryId = item.schoolboundaryid;
                        geo.schoolId = item.schoolid;
                        return {
                            geo,
                            text: item.alternate_names,
                            subText: `${geo.city}, ${geo.state}`,
                            icon: 'icon-cap',
                        };
                    case 'address':
                        return {
                            geo: item.geo,
                            text: item.searchText,
                            subText: '',
                            icon: 'icon-pin',
                        };
                    default:
                        return { geo, text: '', subText: '', icon: '' };
                }
            };

            // Efficient loop with limit enforcement
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                const type = item.geotype;
                if (!group[type]) group[type] = [];

                const limit = limits[type] ?? Infinity;
                if (group[type].length >= limit) continue;

                const { geo, text, subText, icon } = getGeoInfo(item, type);

                group[type].push({
                    type,
                    title: '',
                    action: 'searchBySuggestion',
                    icon,
                    text: text?.replace(', USA', ''),
                    subText,
                    value: geo,
                    searchBy: `${SEARCH_BY.SEARCH_GEO}_${type}`,
                });
            }

            let result = [];
            for (const { type, title } of displayOrder) {
                if (group[type]?.length) {
                    group[type][0].title = title;
                    result.push(...group[type]);
                }
            }

            return result;
        },
        /* Utilities */
        getSearchType(geo, filter) {
            if (filter?.schoolId) return 'school';
            return geo?.geoType || '';
        },
        ga4Track(item) {
            if (!item || !item.searchBy) {
                console.error('searchBy is not defined in item');
                return;
            }

            const { searchBy, url, text, subText } = item;
            let searchType = '';
            let searchTerm = '';

            const isRecentOrSaved = [SEARCH_BY.SEARCH_RECENT, SEARCH_BY.SEARCH_SAVED].includes(searchBy);
            const isPredefinedType = [SEARCH_BY.SEARCH_AGENT, SEARCH_BY.SEARCH_FAVORITE, SEARCH_BY.SEARCH_VETERAN, SEARCH_BY.SEARCH_LENDER, SEARCH_BY.SEARCH_MARKET_TREND].includes(searchBy);

            if (isRecentOrSaved && url) {
                const geo = getGeoByPath(url);
                const filter = getFilterByPath(url);
                searchType = this.getSearchType(geo, filter);

                if (searchBy === SEARCH_BY.SEARCH_SAVED) {
                    searchTerm = text;
                }
            } else if (isPredefinedType) {
                searchType = searchBy.replace('search_', '');
            } else if (searchBy.includes(SEARCH_BY.SEARCH_GEO)) {
                searchType = searchBy.replace(`${SEARCH_BY.SEARCH_GEO}_`, '');
            }

            // Fallbacks
            searchType = searchType || searchBy || '';
            searchTerm = searchTerm || text + (subText && subText.trim() ? ` ${subText}` : '');

            this.$eventBus.$emit(
                'ga4',
                {
                    event: 'search',
                    en: 'search',
                    ec: searchBy,
                },
                {
                    pageData: {
                        msp_term: searchTerm ? format.capitalizeTitle(searchTerm) : '',
                        msp_type: searchType.toUpperCase(),
                    },
                }
            );
        },
        action(item) {
            document.body.classList.add('searching');
            this.hasInput = false;
            this.searchFilter = null;
            try {
                this.ga4Track(item);
            } catch (e) {
                window.sendNodeLog && window.sendNodeLog(`client-error-ga4track`, window.location.href, 0, e);
            }
            if (searchTimer) clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                // if (['recentSearch', 'current'].includes(item.type)) {
                //     if (item.text === this.searchText) {
                //         return;
                //     }
                // }
                switch (item.action) {
                    case 'geoSearch':
                        this.geoSearch();
                        break;
                    case 'redirect':
                        this.redirectTo(item.url, false, item.text);
                        break;
                    case 'redirectGeo':
                        this.redirectTo(item.url, true, item.text);
                        break;
                    case 'searchBySuggestion':
                        this.searchBySuggestion(item);
                        break;
                    case 'searchByInputText':
                        const newVal = (item && item.data) || null;
                        if (!newVal) {
                            return;
                        }
                        this.$eventBus.$emit('eventbus-search', newVal);
                        break;
                    default:
                        if (item.href) {
                            if (item.target) {
                                this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'click', el: item.text, props: { link_name: item.text } });
                                window.open(item.href, item.target);
                                document.body.classList.remove('searching');
                            } else {
                                redirect(item.href);
                            }
                        }
                        break;
                }
            }, 500);
            this.hide();
        },
        createSubtext(item) {
            let bedText = '';
            let priceText = '';
            let icons = '';

            const { minBed, minPrice, maxPrice, propertyType } = item;

            if (minBed && $.notification.valideBed(propertyType)) {
                bedText = `${minBed}+ Beds`;
            }

            const prefix = bedText ? ', ' : '';

            const parsePrice = (price) => Number(String(price).replace(/[^0-9.]+/g, ''));

            if (minPrice && maxPrice) {
                const min = !isNaN(minPrice) ? minPrice : parsePrice(minPrice);
                const max = !isNaN(maxPrice) ? maxPrice : parsePrice(maxPrice);

                if (min && max) {
                    priceText = `${prefix}$${format.friendlyPrice(min)} to $${format.friendlyPrice(max)}`;
                } else if (min) {
                    priceText = `${prefix}More than $${format.friendlyPrice(min)}`;
                } else if (max) {
                    priceText = `${prefix}Less than $${format.friendlyPrice(max)}`;
                }
            }

            const iconMap = {
                SINGLE_FAMILY_HOUSE: 'single-family',
                CONDO: 'condo',
                MULTI_FAMILY: 'multi-family',
                LAND: 'lot',
                MOBILE: 'mobile',
                OTHER: 'other',
            };

            Object.entries(iconMap).forEach(([key, icon]) => {
                if (propertyType.includes(key)) {
                    icons += `<i class="icon-property-${icon}"></i>`;
                }
            });

            return `${icons} ${bedText}${priceText}`.trim();
        },
        recordTolinks() {
            return $.notification
                .getRecords()
                .filter((record) => !record.disabled)
                .sort((a, b) => a.zipcode - b.zipcode)
                .map((record) => ({
                    icon: 'icon-search',
                    value: record.zipcode,
                    text: record.zipcode,
                    subText: this.createSubtext(record),
                    zipcode: record.zipcode,
                    stateCode: record.stateCode,
                    href: $.notification.getUrlByRecord(record),
                    type: 'recentSearch',
                    searchBy: SEARCH_BY.SEARCH_RECENT,
                }));
        },
        /* Actions */
        geoSearch(params) {
            let gpsPath = params?.gpsPath || (this.glb.rentals ? '/rentals/' : '/for-sale/');

            $.geoServiceLocation().then((latlng) => {
                document.body.classList.remove('searching');
                if (latlng) {
                    const url = `${gpsPath}@${latlng.lat},${latlng.lng}/`;
                    this.redirectTo(url, false, 'Current Location');
                    this.$eventBus.$emit('eventbus-showmap', true);
                } else {
                    console.warn('Cannot get current location info');
                    if (!location.pathname.startsWith(gpsPath)) {
                        this.redirectTo(gpsPath, false, 'Current Location');
                    }
                }
            });
        },
        redirectTo(path, isGeo, displayText) {
            document.body.classList.remove('searching');
            if (this.glb.pageType === 'vmapsearch') {
                if (isGeo) {
                    const currentPath = window.location.pathname;
                    const filter = getFilterByPath(currentPath);
                    path += getPathByFilter(filter, true);
                }
                if (displayText) {
                    this.searchText = displayText;
                    this.defaultText = displayText;
                }
                this.$eventBus.$emit('eventbus-redirect-keepfilter', path);
            } else {
                redirect(`${location.origin}${path}`);
            }
        },
        searchBySuggestion(item) {
            if (!item) return;

            const hasAddressComponents = item.value?.address_components?.length > 1;
            const isGoogleGeo = item.value?.isGoogleGeo;
            const text = item.text + (item.subText ? `, ${item.subText}` : '');

            if (hasAddressComponents) {
                this._value = {
                    text,
                    geo: item.value,
                    isGoogleGeo: true,
                };
            } else if (isGoogleGeo) {
                this._searchByText(item.text);
            } else {
                this._value = {
                    text,
                    geo: item.value,
                    isGoogleGeo: false,
                    extraPath: item.extraPath ? `${item.extraPath}/` : undefined,
                };
            }
        },
        _getGeoSuggestionData() {
            return $.getAutoComplete(this.searchText).then((result) => {
                var suggestionList = [];
                if (result && result.length) {
                    result.forEach((obj) => {
                        suggestionList.push({
                            searchText: obj.text,
                            geo: { isGoogleGeo: true },
                            geotype: 'address',
                        });
                    });
                }
                return suggestionList;
            });
        },
        _searchByText(text) {
            $.geoServiceSearch(text).then((results) => {
                const firstResult = results?.[0];
                let geoObj = null;

                if (firstResult?.geometry?.location) {
                    geoObj = firstResult.partial_match
                        ? { address_components: [] }
                        : {
                              address_components: firstResult.address_components,
                              lat: firstResult.geometry.location.lat(),
                              lng: firstResult.geometry.location.lng(),
                          };
                } else if (firstResult) {
                    geoObj = firstResult;
                }

                this._value = {
                    text,
                    isGoogleGeo: true,
                    geo: geoObj,
                };
            }).catch(() => {
                document.body.classList.remove('searching');
            });
        },
        _search() {
            if (this.searchText === this.defaultText) {
                return;
            }
            var text = this.searchText;
            var isMlsNumberFlag = (/^([a-zA-Z\d]+)?[\d]{4,}$/.test(text) && text.length > 5 && text.indexOf(' ') < 0) || /^\d{2}-\d{3,}$/.test(text);
            var suggest = null;
            if (this.searchSuggestions && this.searchSuggestions.length > 0) {
                let index = this.index != -1 ? this.index : 0;
                suggest = this.searchSuggestions[index];
                this.action(suggest);
            } else if (text && this.searchText.length > this.minReqChars) {
                if (!/Current Location/i.test(text)) {
                    if (isMlsNumberFlag) {
                        this._value = {
                            text: text,
                            isMlsNumber: true,
                            geo: {},
                        };
                    } else {
                        this._searchByText(text);
                    }
                    this.hide();
                }
            } else {
                this.$emit('noinput');
            }
        },
        /* Event Listeners & Handlers */
        onKeyup(e) {
            const keyActions = {
                ArrowDown: () => this.next(),
                ArrowUp: () => this.prev(),
                Escape: () => this.hide(),
            };

            if (keyActions[e.key]) {
                return keyActions[e.key]();
            }
        },
        onClickOutside(e) {
            if (!e.target.closest('.mvt-searchbox')) {
                this.hide();
            }
        },
        onInput() {
            this.hasInput = true;
            this.show();
            if (this.searchText.length > this.minReqChars) {
                this.isTyping = true;
                this.getCompleteSuggestionItems();
            }
        },
        onFocus() {
            this.show();
        },
        onSubmit() {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                this._search();
            }, 300);
        },
        next() {
            if (this.index >= this.searchSuggestions.length) {
                this.index = 0;
            } else {
                this.index += 1;
            }
        },
        prev() {
            if (this.index <= 0) {
                this.index = this.searchSuggestions.length - 1;
            } else {
                this.index -= 1;
            }
        },
        /* Visibility */
        hide() {
            if (this.isVisible) {
                this.isVisible = false;
                $.closeOverlayer('searchbox-active');
                requestAnimationFrame(() => {
                    this.index = -1;
                });
            }
        },
        show() {
            if (!this.isVisible) {
                this.isVisible = true;
                $.openOverlayer('searchbox-active');
            }
        },
    },
    beforeUnmount() {
        document.removeEventListener('click', this.onClickOutside);
        this.$eventBus.$off('eventbus-geosearch-apply');
    },
};
