<template>
  <div
      class="mvt-searchbox"
      :class="[
            { 'mvt-searchbox--active': isVisible },
            { 'mvt-searchbox-typing': isTyping },
            `mvt-searchbox--size-${size}`,
            { 'mvt-searchbox--mode-hero': heroMode }
        ]"
  >
    <div class="input right mvt-searchbox__input">
      <!-- Close Button -->
      <button
          type="button"
          aria-label="Hide Search Results"
          class="mvt-searchbox__close"
          @click="hide"
      >
        <i class="icon-angle-left"></i>
      </button>
      <!-- Search Input -->
      <input
          type="text"
          ref="input"
          :placeholder="placeholder"
          autocomplete="off"
          @input="onInput"
          @focus="onFocus"
          @keyup="onKeyup"
          v-model="searchText"
          @keydown.enter.prevent="onSubmit"
      />
      <!-- Submit Button -->
      <button
          type="button"
          class="mvt-searchbox__submit"
          tabindex="0"
          role="button"
          aria-label="Search"
          @keydown.enter.prevent="onSubmit"
          @click="onSubmit"
      >
        <!-- Note: Inline SVG for performance over loading external icons -->
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <g>
            <line class="magnify-line" x1="65" y1="65" x2="75" y2="75"></line>
          </g>
          <g id="loader">
            <circle class="loader-spinner" cx="50" cy="50" r="20"></circle>
          </g>
        </svg>
      </button>
    </div>
    <!-- Suggestion List -->
    <!-- v-show is better than v-if to avoid destruction/re-creation -->
    <div class="mvt-searchbox__list" v-show="isVisible">
      <template v-for="(autoSuggest, i) in searchSuggestions" :key="`${autoSuggest.text}_${i}`">
        <!-- Normal Suggestion -->
        <template v-if="autoSuggest.type !== 'google'">
          <h3 v-if="autoSuggest.title" class="f7 text-uppercase">
            {{ autoSuggest.title }}
          </h3>
          <div :class="{ hover: index === i }">
            <a href="javascript:void(0);" @click.prevent="action(autoSuggest)">
              <i class="f4" :class="autoSuggest.icon"></i>
              <div>
                <b>{{ autoSuggest.text }}</b>
                <div class="f7 text-capitalize" v-html="autoSuggest.subText"></div>
              </div>
            </a>
          </div>
        </template>
        <!-- Google Powered Footer -->
        <h3
            class="text-right"
            v-else
        >
          <img
              height="16px"
              :src="glb.cdnUrl + 'images/desktop/powered_by_google_on_white@3x.png'"
              alt="powered by Google"
              loading="lazy"
          />
        </h3>
      </template>
    </div>
  </div>
</template>

<script src="./mvt-searchbox.js"></script>
